body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240,242,245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.auth{
  margin-top: 200px;
}

.login-form-button {
  width: 100%;
}

.bg-white{
  background-color: white;
  color: black!important;
}

.bg-aqua{
  background-color: #13c2c2;
  color: white!important;
}

.bg-blue{
  background-color: #1677ff;
  color: white!important;
}

.bg-purple{
  background-color: #722ed1;
  color: white!important;
}

.bg-pink{
  background-color: #eb2f96;
  color: white!important;
}

.bg-grey{
  background-color: #434343;
  color: white!important;
}

.bg-orange{
  background-color: #fa8c16;
  color: white!important;
}

.bg-green{
  background-color:#52c41a;
  color: white!important;
}